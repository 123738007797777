import Slider from "react-slick";
import "./styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about2_1 from "../../assets/about2_1.mp4";
import about2_2 from "../../assets/about2_2.mp4";
import about2_3 from "../../assets/about2_3.mp4";
import about2_4 from "../../assets/about2_4.mp4";
import about2_5 from "../../assets/about2_5.mp4";
import about2_6 from "../../assets/about2_6.mp4";
import about2_7 from "../../assets/about2_7.mp4";
import about2_8 from "../../assets/about2_8.mp4";
import about2_9 from "../../assets/about2_9.mp4";
import about2_10 from "../../assets/about2_10.mp4";
import about2_11 from "../../assets/about2_11.mp4";
import about2_12 from "../../assets/about2_12.mp4";
import about3_1 from "../../assets/about3_1.png";
import about3_2 from "../../assets/about3_2.png";
import about3_3 from "../../assets/about3_3.png";

import CtaTelWpp from "../../components/CTATelWpp";
import FormasPagamento from "../../components/FormasPagamento";

const data = [
  {
    id: 1,
    image: about1_1,
    title: "Reformas",
    text: "Realizamos com excelência, pequenas e grande reformas, em sua residência ou empresa.",
  },
  {
    id: 2,
    image: about1_2,
    title: "ELÉTRICA",
    text: "Realizamos instalações e manutenções elétricas, em sua residência ou empresa.",
  },
  {
    id: 3,
    image: about1_3,
    title: "REVESTIMENTOS",
    text: "Aplicamos revestimentos de cerâmicas e porcelanatos em sua residência ou empresa.",
  },
  {
    id: 4,
    image: about1_4,
    title: "Acabamentos",
    text: "Realizamos acabamentos em geral em sua residência ou empresa.",
  },
  {
    id: 5,
    image: about1_5,
    title: "PINTURA",
    text: "Realizamos pinturas em geral em sua residência ou empresa.",
  },
];

const data2 = [
  {
    id: 1,
    video: about2_1,
  },
  {
    id: 2,
    video: about2_2,
  },
  {
    id: 3,
    video: about2_3,
  },
  {
    id: 4,
    video: about2_4,
  },
  {
    id: 5,
    video: about2_5,
  },
  {
    id: 6,
    video: about2_6,
  },
  {
    id: 7,
    video: about2_7,
  },
  {
    id: 8,
    video: about2_8,
  },
  {
    id: 9,
    video: about2_9,
  },
  {
    id: 10,
    video: about2_10,
  },
  {
    id: 11,
    video: about2_11,
  },
  {
    id: 12,
    video: about2_12,
  },
];

const About = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const about1CarouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  };

  const about2CarouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: isMobile ? false : true,
  };

  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">NOSSOS SERVIÇOS</h2>

          <p className="text">
            Quando o assunto é construção, reforma e acabamento, conte com a
            Project!
          </p>

          {isMobile ? (
            <div className="carousel">
              {data.map(({ id, title, text, image }) => {
                return (
                  <div key={id} className="item">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>

                    <div className="bottom">
                      <p className="item_title">{title}</p>

                      <p className="item_text">{text}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <Slider {...about1CarouselSettings} className="carousel">
              {data.map(({ id, title, text, image }) => (
                <div key={id} className="item">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>

                  <div className="bottom">
                    <p className="item_title">{title}</p>
                    <p className="item_text">{text}</p>
                  </div>
                </div>
              ))}
            </Slider>
          )}

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">OBRAS ENTREGUES</h2>

          <p className="text">
            Veja agora algumas obras de manutenção e reforma que entregamos
            recentimente.
          </p>

          <Slider {...about2CarouselSettings} className="carousel">
            {data2.map(({ id, video }) => (
              <video controls key={id} className="video">
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}
          </Slider>

          <p className="text">
            Garanta agora o melhor acabamento para a sua obra ou reforma!
          </p>

          <CtaWpp />
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">PORQUE NOS ESCOLHER?</h2>

          <p className="text">
            Levamos a sério o profissionalismo, nossos serviços tem um ótimo
            acabamento e garantia de qualidade.
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about3_1} alt="EXCELÊNCIA PROFISSIONAL" />
              </figure>

              <p className="item_title">EXCELÊNCIA PROFISSIONAL</p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about3_2} alt="ACABAMENTO IMPECÁVEL" />
              </figure>

              <p className="item_title">ACABAMENTO IMPECÁVEL</p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about3_3} alt="GARANTIA DE QUALIDADE" />
              </figure>

              <p className="item_title">GARANTIA DE QUALIDADE</p>
            </div>
          </div>

          <p className="text2">
            Garanta agora o melhor acabamento para a sua obra ou reforma! <br />
            <span>Fale conosco por telefone ou WhatsApp:</span>
          </p>

          <CtaTelWpp />
        </div>
      </article>

      <FormasPagamento />
    </section>
  );
};

export default About;
