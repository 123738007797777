import { useState } from "react";
import "./styles.css";

import icon from "../../assets/icontel.png";

const CtaTel = () => {
  const [text, setText] = useState("(51) 98977-0375");

  return (
    <button id="ctaTel">
      <a href="tel:">
        <figure>
          <img src={icon} alt="Ícone do Telefone" />
        </figure>
        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaTel;
