import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import header1 from "../../assets/header1.mp4";
import header3_1 from "../../assets/header3_1.png";
import header3_2 from "../../assets/header3_2.png";
import header3_3 from "../../assets/header3_3.png";
import header3_4 from "../../assets/header3_4.png";
import header3_5 from "../../assets/header3_5.png";

import CtaWpp from "../../components/CTAWpp";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: header3_1,
  },
  {
    id: 2,
    image: header3_2,
  },
  {
    id: 3,
    image: header3_3,
  },
  {
    id: 4,
    image: header3_4,
  },
  {
    id: 5,
    image: header3_5,
  },
];

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <div className="left">
            <h1 className="title">
              Somos Especialistas em Construções, Reformas e Acabamentos
            </h1>

            <video controls className="mobile">
              <source src={header1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <p className="text">
              Equipe especializada em pintura, revestimento de cerâmica e
              porcelanato, elétrica, reformas e acabamentos em geral, na sua
              casa ou empresa.
            </p>

            <CtaWpp />
          </div>

          <video width="600" controls className="video">
            <source src={header1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="text">
            Transforme sua casa em um paraíso com a Project Construções e
            Reformas
          </h2>
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 1
                : 1
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="right">
            <h2 className="title">Quem somos</h2>

            <p className="text">
              Nós somos a Project Construções e Reformas, uma empresa que atua
              no setor de construção, reformas e acabamentos, com mais de uma
              década de experiência. Fundada há 10 anos, a Project conta com uma
              equipe de especialistas que se destaca pela excelência nos
              serviços prestados, sempre comprometidos em entregar qualidade e
              satisfação aos nossos clientes.
            </p>

            <Swiper
              className="mobile"
              spaceBetween={20}
              slidesPerView={
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                  navigator.userAgent
                )
                  ? 1
                  : 1
              }
              navigation
              loop
              pagination={{ clickable: true }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
            >
              {data.map(({ id, image }) => {
                return (
                  <SwiperSlide key={id} className="img">
                    <figure>
                      <img src={image} alt={`Exemplo ${id} de Foto`} />
                    </figure>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <p className="text">
              <b>
                Está precisando construir ou dar um up grade no visual do seu
                lar ou empresa? Fale conosco agora mesmo:
              </b>
            </p>

            <CtaWpp />
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
