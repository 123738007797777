import React from "react";
import "./index.css";

import CtaTel from "../CTATel";
import CtaWpp from "../CTAWpp";

const CtaTelWpp = () => {
  return (
    <article id="CtaTelWpp">
      <CtaTel />
      <CtaWpp text={"(51) 98977-0375"} />
    </article>
  );
};

export default CtaTelWpp;
